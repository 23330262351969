.navbar  {
    background-color: black;
}

#about-me {
    margin-left: 10px;
    font-size: xx-large;
}

.navbar__link:hover {
    transform: translateY(-2px);
  }

.navbar__link--active {
    background-color: white;
    color: black;
}
  