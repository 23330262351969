#about  {
    text-align: center;
    color: white
}

body{
    background-image: radial-gradient(grey, rgb(49, 48, 48));
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: 'Sarabun', sans-serif;
    font-size: larger;
}
#about-text  {
    text-align: left;
    margin-top: 10px;
    padding: auto;
    letter-spacing: 2px;
    line-height: 1.3;
    word-spacing: 5px;
    font-weight: 500;
}

#roy-picture    {
    margin-top: 10px;
    float: left;
    border-radius: 20px;
}
  .container .box .title {
    width: 100%;
    align-items: center;
    height: 50px;
  }
  .container .box .title .block {
    width: 0%;
    height: inherit;
    background: #ffb510;
    position: absolute;
    animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    display: flex;
  }
  .container .box .title h1 {
    color: white;
    font-size: 32px;
    -webkit-animation: mainFadeIn 2s forwards;
    -o-animation: mainFadeIn 2s forwards;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 0;
    display: flex;
    
    
  }
  .container .box .title h1 span {
    width: 0px;
    height: 0px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #ffb510;
    -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    animation-delay: 2s;
    margin-left: 5px;
    margin-top: -10px;
    position: absolute;
    bottom: 13px;
    right: -12px;
  }
  .container .box .role {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: -10px;
  }
  .container .box .role .block {
    width: 0%;
    height: inherit;
    background: #e91e63;
    position: absolute;
    animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    animation-delay: 2s;
    display: flex;
  }
  .container .box .role p {
    animation: secFadeIn 2s forwards;
    animation-delay: 3.2s;
    opacity: 0;
    font-weight: 400;
    color: white;
    font-size: 20px;
    margin-top: 10px;
    text-transform: uppercase;
    letter-spacing: 5px;
  }
  
  @keyframes mainBlock {
    0% {
      width: 0%;
      left: 0;
    }
    50% {
      width: 100%;
      left: 0;
    }
    100% {
      width: 0;
      left: 100%;
    }
  }
  @keyframes secBlock {
    0% {
      width: 0%;
      left: 0;
    }
    50% {
      width: 100%;
      left: 0;
    }
    100% {
      width: 0;
      left: 100%;
    }
  }
  @keyframes mainFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes popIn {
    0% {
      width: 0px;
      height: 0px;
      background: #e9d856;
      border: 0px solid #ddd;
      opacity: 0;
    }
    50% {
      width: 10px;
      height: 10px;
      background: #e9d856;
      opacity: 1;
      bottom: 45px;
    }
    65% {
      width: 7px;
      height: 7px;
      bottom: 0px;
      width: 15px;
    }
    80% {
      width: 10px;
      height: 10px;
      bottom: 20px;
    }

  }
  @keyframes secFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 760px)  {
    #about-text {
      font-size: 12px;
    }

  }

  @media only screen and (max-width: 450px) {
    #Roy  {
      font-size: 18px;
    }
    #role {
      font-size: 12px;
    }
  }