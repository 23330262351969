#skills {
  color: whitesmoke;
  text-align: center;
}
.skill-icon {
    padding: 3px;
}
.skillName  {
    background-color: rgb(44, 43, 43);
    color: white;
    text-align: center;
}

.skill-card div {
    cursor: pointer;
    pointer-events: all;
    box-shadow: 5px 5px 0px 0 #3a1372;
    overflow: hidden;
    object-fit:cover;
  }

.skill-card div img {
    width:auto;
    height: auto;
    transition: transform 1s;
  }

.skill-card div:hover img {
    transform: scale(1.1);
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.card-image {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

#skills {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
.skill-title  {
  animation: 1s ease-out 0s 1 slideInFromRight;
}

@media only screen and (max-width: 630px) {
  .skill-title  {
    font-size: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .skill-title  {
    display: flex;
    justify-content: center;
    
  }
}
@media only screen and (max-width: 420px) {
  .skill-title  {
    font-size: 8px;
    display: flex;
    justify-content: center;
  }
  #about-me {
    font-size: 18px;
  }
  #navLink1, #navLink2, #navLink3 {
    font-size: 8px;
  }
}
@media only screen and (max-width: 350px) {
   .skill-title {
    display: flex;
    justify-content: center;
  }
}