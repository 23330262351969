#header{
    color: white;
    
}
#header-pic {
    height: 500px;
    width: 100%;
    margin-top: 10px;
}
.material-icons {
    background-color: black;
    padding: 20px;
    
}

#email-row, #phone-row, #github-row, #linkedin-row {
    font-size: 20px;
    text-align: left;
}
#github-icon    {
    background-color: black;
    padding: 20px;
}
#linkedin-icon  {
    background-color: black;
    padding: 15px;
}


