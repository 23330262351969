.project div {
    cursor: pointer;
    pointer-events: all;
    box-shadow: 5px 5px 0px 0 #3a1372;
    overflow: hidden;
    border-radius: 10px;
    object-fit:cover;
  }
  .project  {
    border-radius: 10px;
  }
  .title  {
    font-size: 30px;
    font-weight: 700;
  }
  .description  {
    font-size: 15px;
    color: rgb(223, 219, 219)
  }

.project div img {
    width:auto;
    height: auto;
    transition: transform 1s;
  }

.project div:hover img {
    transform: scale(1.1);
}
.project div:hover div {
    transform: scale(1.05);
}
.card-content {
  background-color: rgb(59, 57, 57);
  color:white;
}

.learn-more {
  margin: 5px;
}
.learn-more, .try-me  {
  background-color: white;
  color: black;
}
#myProjects {
  color: whitesmoke;
  text-align: center;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.project {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
#myProjects {
  animation: 1s ease-in 0s 1 slideInFromRight;
}
@media only screen and (max-width: 750px) {
  .description  {
    font-size: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .learn-more, .try-me {
    display: inline-block;
    margin-left: 5px;
  }
  .project-picture  {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 597px) {
  .title {
    font-size: 16px;
  }
  .description  {
    font-size: 10px;
  }
  .card-image .project-picture  {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 340px) {
  .learn-more, .try-me  {
    font-size: 10px;
  }
}